import { MenuItem, MenuList } from "@material-ui/core"

import { useFetchHomeShortcodes } from "src/data/guestCommunication/queries/guestCommunicationQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownButton } from "src/ui/Button/DropdownButton"

export function ShortcodeDropdown({
  homeId,
  onSelect,
}: {
  homeId: string
  onSelect: (strings: string) => void
}) {
  const { t } = useTranslate()
  const shortcodeQuery = useFetchHomeShortcodes({ homeId })

  function handleChange(shortcode: string) {
    onSelect(shortcode)
  }

  return (
    <DropdownButton
      displayValue={t(langKeys.guest_communication_add_short_code)}
      variant="minimal"
      placement="bottom-end"
    >
      {({ closeMenu }) => (
        <MenuList>
          {shortcodeQuery.isLoading && (
            <MenuItem disabled>{t(langKeys.loading)}...</MenuItem>
          )}
          {shortcodeQuery.data?.map((shortcode) => {
            return (
              <MenuItem
                key={shortcode.shortcode}
                onClick={() => {
                  handleChange(shortcode.shortcode)
                  closeMenu()
                }}
              >
                {shortcode.shortcode_name}
              </MenuItem>
            )
          })}
        </MenuList>
      )}
    </DropdownButton>
  )
}
