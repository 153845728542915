import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"

export function HomeSettingsProfileDynamic() {
  return (
    <span>
      You can add new settings profiles from{" "}
      <InternalLink to={Routes.MonitoringSettings.location().pathname}>
        Settings
      </InternalLink>
      .
    </span>
  )
}
