import { TMonitoringPresetType } from "src/components/Homes/HomeDetails/HomeSettings/HomeMonitoringPresets"

import { usePostTrackEventWithParams } from "./analyticsQueries"

export type THomesettingsEventTypes = "Open Preset Clicked"

function usePostOpenPresetTrackingEvent<TEventProperties>(
  event: THomesettingsEventTypes
) {
  return usePostTrackEventWithParams<THomesettingsEventTypes, TEventProperties>(
    {
      event,
    }
  )
}

export function usePostOpenPresetClicked() {
  return usePostOpenPresetTrackingEvent<{
    userId: string
    homeId?: string
    orgId: string
    type: TMonitoringPresetType
  }>("Open Preset Clicked")
}
