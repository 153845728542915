import styled from "styled-components"

import { HomeAddress } from "src/components/Homes/HomeDetails/HomeSettings/HomeAddress"
import { HomeMonitoringPresets } from "src/components/Homes/HomeDetails/HomeSettings/HomeMonitoringPresets"
import { RemoveHome } from "src/components/Homes/HomeDetails/Overview/RemoveHome"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { SettingDropdown } from "src/components/Settings/Setting/SettingDropdown"
import { useBackendFlags } from "src/data/flags/useBackendFlags"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { THome } from "src/data/homes/types/homeTypes"
import { HomeSettingsProfileDynamic } from "src/data/homeSettings/logic/HomeSettingsJsx"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { Divider } from "src/ui/Divider/Divider"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function HomeSettings({ home }: { home: THome }) {
  const { noise_profiles_released: presets } = useBackendFlags()

  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()

  const { org, orgId } = useOrganization()

  const fetchProfiles = useFetchProfiles({
    orgId,
  })

  const { ready, value } = useIsHomeGroupAdmin()
  const isHomeGroupAdmin = ready && value
  const profiles = fetchProfiles.data?.profiles || []
  const patchHome = usePatchHome()

  const access = getAccessLogic({ role: org.user_role })
  const homeAccess = getAccessLogic({ role: home.user_role })

  const canRemoveHome = access.hasAdminAccess || isHomeGroupAdmin

  if (!home) {
    return <div>"Could not find the home.."</div>
  }

  async function handleSubmit(
    newProfileId: string
  ): TSettingContainerOnSaveReturnType {
    try {
      await patchHome.mutateAsync({
        homeId: home.home_id,
        orgId: org.id,
        data: { profile_id: newProfileId },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  const currentProfileName =
    profiles.find((profile) => profile.id === home.profile_id)?.name || ""

  return (
    <HomeSettingsBox $removeMaxWidth={!!presets}>
      <MText variant="heading2">{t(langKeys.settings)}</MText>

      <Show if={!!presets}>
        <HomeMonitoringPresets
          homeId={home.home_id}
          activeNoisePresetId={home.noise_profile_id}
          activeICMPresetId={home.icm_profile_id}
        />
        <Divider $margin={spacing.L} />
      </Show>

      <Show if={profiles.length > 1 && !presets}>
        <SettingDropdown
          title={t(langKeys.profile_title)}
          description={
            <div>
              {t(langKeys.profile_short_description)}
              <HomeSettingsProfileDynamic />
            </div>
          }
          value={home.profile_id || ""}
          options={profiles.map((profile) => ({
            label: profile.name,
            value: profile.id,
          }))}
          onSave={handleSubmit}
          displayValue={currentProfileName}
          disabled={!canRemoveHome}
        />
        <Divider $margin={spacing.L} />
      </Show>

      <HomeAddress
        homeId={home.home_id}
        location={home.location}
        address={home.address}
        editable={homeAccess.hasAdminAccess}
      />

      <Divider $margin={spacing.L} />

      <RemoveHome
        homeId={home.home_id}
        homeName={home.name}
        disabled={!canRemoveHome}
        onRemoveSuccess={() => navigate(Routes.Homes.location())}
      />
    </HomeSettingsBox>
  )
}

const HomeSettingsBox = styled.div<{ $removeMaxWidth: boolean }>`
  display: grid;
  ${({ $removeMaxWidth }) => !$removeMaxWidth && `max-width: 600px;`}
  grid-gap: ${spacing.M};
`
