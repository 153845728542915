import { CSSProperties } from "react"
import styled from "styled-components"

import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { spacing } from "src/ui/spacing"

const Chart = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCircularProgress = styled(MCircularProgress)`
  margin-right: ${spacing.L};
  color: #3fa5da;
`

export function GraphBox({
  isLoading,
  children,
  ...props
}: {
  isLoading?: boolean
  children: React.ReactNode
  style?: CSSProperties
}) {
  return (
    <Chart {...props}>
      {isLoading ? (
        <div>
          <StyledCircularProgress />
        </div>
      ) : (
        children
      )}
    </Chart>
  )
}
